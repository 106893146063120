import Link from 'next/link';
import Image from 'next/legacy/image';
import { Box, Heading, Text, Flex, Button, Icon } from '@chakra-ui/react';
import { URLS } from '@lib';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { analytics } from 'pages';
import { rudderTrack } from '@hooks/rudderTrack';

export function Pricing(): JSX.Element {
  return (
    <Flex
      flexDir="column"
      alignItems={{ base: 'center', lg: 'flex-start' }}
      justifyContent={{ base: 'center', lg: 'space-evenly' }}
      mb={12}
      mr={{ lg: 12 }}
    >
      <Box
        boxSize={{
          base: 16,
          md: 20
        }}
        mb={10}
        pos="relative"
        h="36px"
      >
        <Box
          h="36px"
          w="4px"
          bg="primary.500"
          pos="absolute"
          left="-30%"
          top="30%"
          display={{ base: 'none', lg: 'inline-flex' }}
        ></Box>
        <Image src="/images/tag-icon.svg" layout="fill" alt="" />
      </Box>

      <Heading
        mb={4}
        zIndex={2}
        textAlign={{ base: 'center', lg: 'left' }}
        size={{ base: 'h3', md: 'h5' }}
        pos="relative"
        top="18px"
      >
        Pricing built for
        <Box color="primary.500">businesses of all sizes.</Box>
      </Heading>

      <Text
        color="black"
        paddingTop={2}
        mb={8}
        flexDir={{ base: 'column', lg: 'row' }}
        textAlign={{ base: 'center', lg: 'left' }}
        w="80%"
        pos="relative"
        top="12px"
      >
        Learn more about our transparent per-user costs to estimate how much
        your company could save by implementing Clerk.
      </Text>

      <Box
        display="flex"
        w={'full'}
        flexDir={{ base: 'column', md: 'row' }}
        justifyContent={{ base: 'center', lg: 'flex-start' }}
        alignItems={{ base: 'center' }}
        gap={4}
        pos="relative"
        top="14px"
      >
        <Link legacyBehavior href={URLS.pricing} passHref>
          <Button
            as="a"
            px={8}
            variant="white"
            w={{ base: '84vw', md: 225 }}
            h={12}
            fontSize="md"
            onClick={() => {
              analytics.track('Marketing_Pricing_View Pricing Button Clicked', {
                surface: 'Marketing',
                location: 'Pricing'
              });
              rudderTrack('Marketing_Pricing_View Pricing Button Clicked', {
                surface: 'Marketing',
                location: 'Pricing'
              });
            }}
            rightIcon={<Icon as={ArrowRightIcon} />}
            display="flex"
          >
            View pricing
          </Button>
        </Link>
      </Box>
    </Flex>
  );
}
