export const rudderPage = async (
  name: string,
  {
    category,
    title,
    surface,
    location
  }: { category: string; title: string; surface: string; location: string }
) => {
  const rudderanalytics = await import('rudder-sdk-js');
  return rudderanalytics.page(name, {
    category: category,
    name: title,
    surface: surface,
    location: location
  });
};
