import {
  ExpoLogo,
  FaunaLogo,
  GatsbyLogo,
  GrafbaseLogo,
  HasuraLogo,
  FirebaseLogo,
  NextJSLogo,
  ReactLogo,
  RedwoodLogo,
  RemixLogo,
  SupabaseLogo
} from '@components/common/logos';
import {
  APISDKIcon,
  SupportIcon,
  DiscordIcon,
  MapIcon,
  TutorialsIcon,
  StatusIcon,
  EmailMagicLinksIcon,
  EmailSMSIcon,
  InvitationsIcon,
  MultifactorIcon,
  OrgProfileIcon,
  OrgSwitcherIcon,
  PasswordIcon,
  RolesPermissionsIcon,
  SAMLIcon,
  SecurityIcon,
  SessionManagementIcon,
  SignInIcon,
  SignUpIcon,
  SocialSSOIcon,
  UserButtonIcon,
  UserProfileIcon,
  Web3Icon,
  BlogIcon,
  CareersIcon,
  ClerkIcon,
  OpenSourceIcon,
  SecurityClerkIcon
} from './icons';
import {
  MapGradient,
  TutorialsGradient,
  APISDKGradient,
  StatusGradient,
  SupportGradient,
  DiscordGradient,
  PasswordGradient,
  EmailMagicLinksGradient,
  EmailSMSGradient,
  InvitationsGradient,
  MultifactorGradient,
  OrgProfileGradient,
  OrgSwitcherGradient,
  RolesPermissionsGradients,
  SAMLGradient,
  SecurityGradient,
  SessionManagementGradient,
  SignInGradient,
  SignUpGradient,
  SocialSSOGradient,
  UserButtonGradient,
  UserProfileGradient,
  Web3Gradient,
  BlogGradient,
  CareersGradient,
  ClerkGradient,
  OpenSourceGradient,
  SecurityClerkGradient
} from './gradients';
import { NodeIcon, RubyIcon, GoIcon } from '@components/common/icons';

export const ProductMobileSections = [
  {
    name: 'Pre-built Components',
    description: 'Components for sign up, sign in, user management, and more.',
    features: [
      {
        label: '<SignIn />',
        gradient: SignInGradient,
        icon: SignInIcon,
        href: '/components?component=SignIn#auth-user-components'
      },
      {
        label: '<SignUp />',
        gradient: SignUpGradient,
        icon: SignUpIcon,
        href: '/components?component=SignUp#auth-user-components'
      },
      {
        label: '<UserButton />',
        gradient: UserButtonGradient,
        icon: UserButtonIcon,
        href: '/components?component=UserButton#auth-user-components'
      },
      {
        label: '<UserProfile />',
        gradient: UserProfileGradient,
        icon: UserProfileIcon,
        href: '/components?component=UserProfile#auth-user-components'
      },
      {
        label: '<OrganizationProfile />',
        gradient: OrgProfileGradient,
        icon: OrgProfileIcon,
        href: '/components?component=OrganizationProfile#b2b-components'
      },
      {
        label: '<OrganizationSwitcher />',
        gradient: OrgSwitcherGradient,
        icon: OrgSwitcherIcon,
        href: '/components?component=OrganizationSwitcher#b2b-components'
      }
    ]
  }
];

export const ProductSections = [
  {
    name: 'Pre-built Components',
    description: 'Components for sign up, sign in, user management, and more.',
    features: [
      {
        label: '<SignIn />',
        gradient: SignInGradient,
        icon: SignInIcon,
        href: '/components?component=SignIn#auth-user-components'
      },
      {
        label: '<UserButton />',
        gradient: UserButtonGradient,
        icon: UserButtonIcon,
        href: '/components?component=UserButton#auth-user-components'
      },
      {
        label: '<SignUp />',
        gradient: SignUpGradient,
        icon: SignUpIcon,
        href: '/components?component=SignUp#auth-user-components'
      },
      {
        label: '<OrganizationProfile />',
        gradient: OrgProfileGradient,
        icon: OrgProfileIcon,
        href: '/components?component=OrganizationProfile#b2b-components'
      },
      {
        label: '<UserProfile />',
        gradient: UserProfileGradient,
        icon: UserProfileIcon,
        href: '/components?component=UserProfile#auth-user-components'
      },
      {
        label: '<OrganizationSwitcher />',
        gradient: OrgSwitcherGradient,
        icon: OrgSwitcherIcon,
        href: '/components?component=OrganizationSwitcher#b2b-components'
      }
    ]
  },
  {
    name: 'Authentication & Users',
    description:
      'Passwords, SSO, OTP, MFA and other advanced security features.',
    features: [
      {
        label: 'Passwords',
        gradient: PasswordGradient,
        icon: PasswordIcon,
        href: '/features/passwords'
      },
      {
        label: 'Email Magic Links',
        gradient: EmailMagicLinksGradient,
        icon: EmailMagicLinksIcon,
        href: '/features/email-magic-links'
      },

      {
        label: 'Social SSO',
        gradient: SocialSSOGradient,
        icon: SocialSSOIcon,
        href: '/features/social-sso'
      },
      {
        label: 'Session Management',
        gradient: SessionManagementGradient,
        icon: SessionManagementIcon,
        href: '/features/session-management'
      },
      {
        label: 'Advanced Security',
        gradient: SecurityGradient,
        icon: SecurityIcon,
        href: '/features/security'
      },
      {
        label: 'Email & SMS (OTP)',
        gradient: EmailSMSGradient,
        icon: EmailSMSIcon,
        href: '/features/email-sms-passcodes-otp'
      },

      {
        label: 'Multifactor Authentication',
        gradient: MultifactorGradient,
        icon: MultifactorIcon,

        href: '/features/multifactor-authentication'
      },
      {
        label: 'Web3 Authentication',
        gradient: Web3Gradient,
        icon: Web3Icon,
        href: '/features/web3'
      }
    ]
  },
  {
    name: 'Organizations (for B2B SaaS)',
    description:
      'UI components, invitations, and member roles for organizations.',

    features: [
      {
        label: 'Invitations',
        gradient: InvitationsGradient,
        icon: InvitationsIcon,
        href: '/docs/custom-flows/invitations'
      },
      {
        label: 'SAML SSO',
        gradient: SAMLGradient,
        icon: SAMLIcon,
        href: '/docs/authentication/saml-at-clerk'
      },
      {
        label: 'Roles and permissions',
        gradient: RolesPermissionsGradients,
        icon: RolesPermissionsIcon,
        href: '/docs/organizations/managing-roles'
      }
    ]
  }
];

export const DevelopersSections = [
  {
    name: 'Resources',
    description: 'Where to find tutorials, support, community, and more.',

    features: [
      {
        label: 'Community Discord',
        external: true,
        gradient: DiscordGradient,
        icon: DiscordIcon,
        href: 'https://discord.com/invite/b5rXHjAg7A'
      },
      {
        label: 'API Status',
        external: true,
        icon: StatusIcon,
        href: 'https://status.clerk.com/',
        gradient: StatusGradient
      },
      {
        label: 'API & SDK Reference',
        external: true,
        icon: APISDKIcon,
        href: '/docs/references/overview',
        gradient: APISDKGradient
      },
      {
        label: 'Feature Requests',
        external: true,
        icon: MapIcon,
        href: 'https://feedback.clerk.com',
        gradient: MapGradient
      },
      {
        label: 'Tutorials',
        icon: TutorialsIcon,
        href: '/tutorials',
        gradient: TutorialsGradient
      },
      {
        label: 'Support',
        gradient: SupportGradient,
        icon: SupportIcon,
        href: '/support'
      }
    ]
  },

  {
    name: 'SDKs & Integrations',
    description: 'Official support for the frameworks you love.',

    features: [
      {
        label: 'NextJS',
        external: false,
        icon: NextJSLogo,
        gradient: NextJSLogo,
        href: '/solutions/nextjs-authentication'
      },
      {
        label: 'Remix',
        external: false,
        icon: RemixLogo,
        gradient: RemixLogo,
        href: '/solutions/remix-authentication'
      },
      {
        label: 'Node / Express',
        external: false,
        icon: NodeIcon,
        gradient: NodeIcon,
        href: '/solutions/node'
      },
      {
        label: 'React',
        external: false,
        icon: ReactLogo,
        gradient: ReactLogo,
        href: '/solutions/react-authentication'
      },
      {
        label: 'Gatsby',
        external: false,
        icon: GatsbyLogo,
        gradient: GatsbyLogo,
        href: '/solutions/gatsby'
      },

      {
        label: 'Ruby / Rails',
        external: false,
        icon: RubyIcon,
        gradient: RubyIcon,
        href: '/solutions/ruby'
      },
      {
        label: 'React Native',
        external: false,
        icon: ExpoLogo,
        gradient: ExpoLogo,
        href: '/solutions/react-native-expo'
      },
      {
        label: 'RedwoodJS',
        external: false,
        icon: RedwoodLogo,
        gradient: RedwoodLogo,
        href: '/solutions/redwood'
      },
      {
        label: 'Go',
        external: true,
        icon: GoIcon,
        gradient: GoIcon,
        href: '/docs/references/go/overview'
      }
    ]
  },
  {
    name: 'Third-Party Integrations',
    description: 'Use your favorite platforms and tools with Clerk.',

    features: [
      {
        label: 'Hasura',
        icon: HasuraLogo,
        gradient: HasuraLogo,
        external: false,
        href: '/integrations/hasura'
      },
      {
        label: 'Supabase',
        icon: SupabaseLogo,
        gradient: SupabaseLogo,
        external: false,
        href: '/integrations/supabase'
      },
      {
        label: 'Fauna',
        icon: FaunaLogo,
        gradient: FaunaLogo,
        external: false,
        href: '/integrations/fauna'
      },

      {
        label: 'Grafbase',
        icon: GrafbaseLogo,
        gradient: GrafbaseLogo,
        external: false,
        href: '/integrations/grafbase'
      },
      {
        label: 'Firebase',
        icon: FirebaseLogo,
        gradient: FirebaseLogo,
        external: false,
        href: '/integrations/firebase'
      }
    ]
  }
];

export const CompanySections = [
  {
    name: 'Company',
    description: 'Sign up, sign in, and user profile management.',

    features: [
      {
        label: 'About Us',
        icon: ClerkIcon,
        gradient: ClerkGradient,
        href: '/company'
      },
      {
        label: 'Blog',
        icon: BlogIcon,
        gradient: BlogGradient,
        href: '/blog'
      },
      {
        label: 'Open Source',
        icon: OpenSourceIcon,
        gradient: OpenSourceGradient,
        href: 'https://github.com/clerkinc',
        external: true
      },
      {
        label: 'Security at Clerk',
        icon: SecurityClerkIcon,
        gradient: SecurityClerkGradient,
        href: '/features/security'
      },
      {
        label: `Careers (we're hiring!)`,
        icon: CareersIcon,
        gradient: CareersGradient,
        href: 'https://jobs.ashbyhq.com/clerk',
        external: true
      }
    ]
  }
];
